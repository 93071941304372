import {
  ApiResponse,
  PaperCompletionResponseBody,
  PaperCompletionResponseDataElement,
} from '@/api/ApiResponse';
import { debounce } from '@/debounce';
import { FacetType } from '@/constants/FacetType';
import { getFieldOfStudyByName } from '@/constants/FieldOfStudy';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { getString } from '@/content/i18n';
import {
  KEY_CODE_DOWN,
  KEY_CODE_ENTER,
  KEY_CODE_ESC,
  KEY_CODE_TAB,
  KEY_CODE_UP,
} from '@/constants/KeyCode';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { Nullable, ObjectProperties } from '@/utils/types';
import { QueryStores } from '@/stores/QueryStoresType';
import { SuggestionType } from '@/models/SuggestionType';
import Api from '@/api/Api';
import AuthorStore from '@/stores/author/AuthorStore';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import EventTarget from '@/analytics/constants/EventTarget';
import FlexContainer from '@/components/shared/layout/FlexContainer';
import HighlightedField from '@/components/shared/common/HighlightedField';
import Icon from '@/components/shared/icon/Icon';
import logger from '@/logger';
import PaperStore from '@/stores/PaperStore';
import S2History from '@/utils/S2History';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';

export const SUGGESTION_DEBOUNCE_DELAY_MS = 400;
const MIN_QUERY_LENGTH = 3;

type Props = {
  formId: string;
  containerClass: string;
  placeholder: string;
  suggestionType: ObjectProperties<typeof SuggestionType>;
  injectQueryStore: QueryStores;
  isSearchbarFocused?: Nullable<boolean>;
  onSearchbarCondense?: Nullable<() => void>;
  onSearchbarFocus?: Nullable<() => void>;
};

type State = {
  suggestQueryText: string;
  showSuggestions: boolean;
  suggestions: Immutable.List<PaperCompletionResponseDataElement>;
  selectedSuggestionIndex: Nullable<number>;
} & StateFromQueryStore &
  StateFromPaperStore &
  StateFromAuthorStore;

type StateFromQueryStore = {
  query: unknown;
  queryText: string;
};

type StateFromPaperStore = {
  paperDetail: ReturnType<PaperStore['getPaperDetail']>;
};

type StateFromAuthorStore = {
  authorDetail: ReturnType<AuthorStore['getAuthorDetails']>;
};

export default class SearchWithinTextSearch extends React.Component<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authorStore: PropTypes.instanceOf(AuthorStore).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    paperStore: PropTypes.instanceOf(PaperStore).isRequired,
    router: PropTypes.object.isRequired,
  };

  declare context: {
    api: Api;
    authorStore: AuthorStore;
    history: S2History;
    paperStore: PaperStore;
    router: Record<string, unknown>;
  };

  constructor(...args: [any]) {
    super(...args);
    const stateFromQueryStore = this.getStateFromQueryStore();
    const { queryText } = stateFromQueryStore;

    this.state = {
      ...stateFromQueryStore,
      ...this.getStateFromPaperStore(),
      ...this.getStateFromAuthorStore(),
      suggestQueryText: queryText,
      showSuggestions: false,
      suggestions: Immutable.List(),
      selectedSuggestionIndex: null,
    };

    this.props.injectQueryStore.registerComponent(this, () => {
      const { queryText } = this.getStateFromQueryStore();
      this.setState({
        queryText,
        suggestQueryText: queryText,
        suggestions: Immutable.List(),
      });
    });

    this.context.paperStore.registerComponent(this, () => {
      this.setState(this.getStateFromPaperStore());
    });

    this.context.authorStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthorStore());
    });
  }

  // The typing isn't quite right here. Prettier doesn't like ReturnType<typeof debounce<() => void>> so we're doing just typeof debounce instead
  fetchSuggestions: ReturnType<typeof debounce>;

  componentDidMount() {
    // Rate limit API requests until the user has stopped typing for a moment (though fire on the
    // leading edge to get quick results for typing one character). This only needs to happen on
    // the client, as we don't fetch suggestions on the server.
    this.fetchSuggestions = debounce(this.doFetchSuggestions, SUGGESTION_DEBOUNCE_DELAY_MS, {
      leading: true,
      trailing: true,
    });
  }

  componentWillUnmount() {
    if (this.fetchSuggestions) {
      this.fetchSuggestions.cancel();
    }
  }

  getStateFromQueryStore = (): StateFromQueryStore => {
    return {
      query: this.props.injectQueryStore.getQuery(),
      queryText: this.props.injectQueryStore.getQuery().queryString || '',
    };
  };

  getStateFromPaperStore = (): StateFromPaperStore => {
    return {
      paperDetail: this.context.paperStore.getPaperDetail(),
    };
  };

  getStateFromAuthorStore = (): StateFromAuthorStore => {
    return {
      authorDetail: this.context.authorStore.getAuthorDetails(),
    };
  };

  doFetchSuggestions = (): void => {
    const { queryText } = this.state;
    const { suggestionType } = this.props;

    if (queryText.length < MIN_QUERY_LENGTH) {
      return;
    }

    switch (suggestionType) {
      case SuggestionType.PAPER_CITATION:
        this.fetchPaperCitationCompletions();
        break;
      case SuggestionType.PAPER_REFERENCE:
        this.fetchPaperReferenceCompletions();
        break;
      case SuggestionType.AUTHOR_PAPER:
        this.fetchAuthorPaperCompletions();
        break;
      default:
        logger.warn(
          `Invalid: SuggestionType ${suggestionType} is not associated with a completion call.`
        );
        return;
    }
  };

  fetchPaperCitationCompletions = (): void => {
    const { paperDetail, queryText } = this.state;
    const { api } = this.context;

    if (!paperDetail) {
      return;
    }

    const paperId = paperDetail.paper.id;

    api
      .fetchCitationCompletions({ paperId, prefixQuery: queryText })
      .then(payload => this.setSuggestions(payload, false));
  };

  fetchPaperReferenceCompletions = (): void => {
    const { paperDetail, queryText } = this.state;
    const { api } = this.context;

    const paperId = paperDetail.paper.id;

    api
      .fetchReferenceCompletions({ paperId, prefixQuery: queryText })
      .then(payload => this.setSuggestions(payload, false));
  };

  fetchAuthorPaperCompletions = (): void => {
    const { authorDetail, queryText } = this.state;
    const { api } = this.context;

    if (!authorDetail) {
      return;
    }
    const authorId = authorDetail.author?.id;

    if (authorId == null) {
      return;
    }

    api
      .fetchAuthorPaperCompletions({ authorId, prefixQuery: queryText })
      .then(payload => this.setSuggestions(payload, true));
  };

  setSuggestions = (
    payload: ApiResponse<PaperCompletionResponseBody>,
    useCoAuthor: boolean
  ): void => {
    const { queryText } = this.state;
    const suggestions = Immutable.List(payload.resultData.completions);

    // If we are on the author page, we want to display the suggestions for authors as Co-Authors
    if (useCoAuthor) {
      suggestions.map(suggestion => {
        if (suggestion.completionType === FacetType.AUTHOR.pluralId) {
          suggestion.completionType = FacetType.COAUTHOR.pluralId;
        }
      });
    }

    this.setState(prevState => {
      const suggestQueryText =
        prevState.queryText === queryText ? queryText : prevState.suggestQueryText;
      return {
        suggestions,
        suggestQueryText,
      };
    });
  };

  onChangeQueryText: ChangeEventHandler<HTMLInputElement> = (event): void => {
    const queryText = event.currentTarget.value;

    if (queryText.length >= MIN_QUERY_LENGTH) {
      this.setState(
        {
          queryText,
          showSuggestions: true,
          selectedSuggestionIndex: null,
        },
        this.fetchSuggestions
      );
    } else {
      this.setState({
        queryText,
        suggestQueryText: queryText,
        showSuggestions: false,
        selectedSuggestionIndex: null,
      });
    }
  };

  clickSuggestion = (): void => {
    this.setState(
      {
        showSuggestions: false,
      },
      () => this.toggleFacetToSelectedSuggestion()
    );

    // We want to condense the search bar when a suggestion is chosen if its a prop
    if (this.props.onSearchbarCondense) {
      this.props.onSearchbarCondense();
    }
  };

  toggleFacetToSelectedSuggestion = (): void => {
    const { selectedSuggestionIndex, suggestions } = this.state;
    const { injectQueryStore } = this.props;
    const { router } = this.context;

    if (selectedSuggestionIndex === null) {
      return;
    }

    const selectedSuggestion = suggestions.get(selectedSuggestionIndex);

    if (selectedSuggestion == null) {
      return;
    }

    const filterType = selectedSuggestion.completionType;
    const filterValue = selectedSuggestion.completion.text;

    switch (filterType) {
      case FacetType.AUTHOR.pluralId:
        injectQueryStore.routeToToggleFilter(FacetType.AUTHOR.id, filterValue, router);
        break;
      case FacetType.COAUTHOR.pluralId:
        injectQueryStore.routeToToggleFilter(FacetType.COAUTHOR.id, filterValue, router);
        break;
      case FacetType.VENUE.id:
        injectQueryStore.routeToToggleFilter(FacetType.VENUE.id, filterValue, router);
        break;
      case FacetType.FIELDS_OF_STUDY.pluralId: {
        const field = getFieldOfStudyByName(filterValue);
        injectQueryStore.routeToToggleFilter(FacetType.FIELDS_OF_STUDY.pluralId, field.id, router);
        break;
      }
    }
  };

  onSubmitQueryString: FormEventHandler = (event): void => {
    event.preventDefault();
    const { router } = this.context;
    const { injectQueryStore, suggestionType } = this.props;
    const queryString = this.state.queryText.trim();

    // not every query store that can be injected has routeToQueryString. This checks for it and only fires if it has it
    if ('routeToQueryString' in injectQueryStore) {
      injectQueryStore.routeToQueryString(queryString, router);

      const eventTarget = this.getEventTarget(suggestionType);
      trackAnalyticsEvent(SubmitEvent.create(eventTarget, { queryString }));
    }
  };

  getEventTarget = (suggestionType: ObjectProperties<typeof SuggestionType>): string => {
    switch (suggestionType) {
      case SuggestionType.PAPER_CITATION:
        return EventTarget.PaperDetail.Citations.SEARCH;
      case SuggestionType.PAPER_REFERENCE:
        return EventTarget.PaperDetail.Citations.SEARCH;
      case SuggestionType.AUTHOR_PAPER:
        return EventTarget.AuthorHomePage.Publications.SEARCH;
      default:
        logger.warn(
          `Invalid: SuggestionType ${suggestionType} is not associated with an event target.`
        );
        return '';
    }
  };

  onSuggestionMouseDown: MouseEventHandler = e => {
    // prevent blur when clicking on suggestions
    e.preventDefault();
  };

  onKeyDown: KeyboardEventHandler = e => {
    const { selectedSuggestionIndex, suggestions } = this.state;
    const index = selectedSuggestionIndex || 0;

    switch (e.keyCode) {
      case KEY_CODE_ENTER: {
        if (selectedSuggestionIndex !== null) {
          e.preventDefault();
          this.clickSuggestion();
        }
        break;
      }

      case KEY_CODE_TAB: {
        if (selectedSuggestionIndex !== null) {
          e.preventDefault();
          this.clickSuggestion();
          this.setState({
            showSuggestions: false,
            selectedSuggestionIndex: null,
          });
        }
        break;
      }

      case KEY_CODE_ESC: {
        this.setState({
          queryText: '',
          suggestQueryText: '',
          showSuggestions: false,
          selectedSuggestionIndex: null,
        });
        break;
      }

      case KEY_CODE_UP: {
        if (this.state.showSuggestions && !suggestions.isEmpty()) {
          e.preventDefault();
          if (selectedSuggestionIndex === null) {
            this.selectSuggestion(suggestions.size - 1);
          } else if (selectedSuggestionIndex === 0) {
            this.selectNone();
          } else {
            this.selectSuggestion(index - 1);
          }
        }
        break;
      }

      case KEY_CODE_DOWN: {
        if (this.state.showSuggestions && !suggestions.isEmpty()) {
          e.preventDefault();
          if (selectedSuggestionIndex === null) {
            this.selectSuggestion(0);
          } else if (selectedSuggestionIndex === suggestions.size - 1) {
            this.selectNone();
          } else {
            this.selectSuggestion(index + 1);
          }
        }
        break;
      }
    }
  };

  onFocus = (): void => {
    if (this.props.onSearchbarFocus) {
      this.props.onSearchbarFocus();
    }
    this.setState({ showSuggestions: true }, this.fetchSuggestions);
  };

  onBlur = (): void => {
    this.setState({ showSuggestions: false });
  };

  selectSuggestion(index: number, afterSelected?: () => void): void {
    this.setState({ selectedSuggestionIndex: index }, afterSelected);
  }

  selectNone = (): void => {
    if (this) {
      this.setState({ selectedSuggestionIndex: null });
    }
  };

  shouldDisplaySuggestions = (state = this.state): boolean => {
    return state.showSuggestions && !state.suggestions.isEmpty();
  };

  renderSuggestions = (): ReactNode => {
    const { selectedSuggestionIndex, suggestions } = this.state;

    return (
      <ul
        className="suggestion-dropdown-menu"
        data-test-id="citations-autocomplete-suggestions"
        onMouseLeave={this.selectNone}
        role="listbox">
        {suggestions.map(({ completionType, completion }, i) => {
          const suggestionClass = classNames('flex-row-vcenter suggestion truncate-line', {
            cursor: i === selectedSuggestionIndex,
            'border-bottom': i !== suggestions.size - 1,
          });
          const onSuggestionClicked = () => {
            this.selectSuggestion(i, () => {
              this.clickSuggestion();
            });
          };
          const shortTypeLabel = completionType
            ? getString(_ => _.filterBar.shortLabels[completionType])
            : '';

          const _onClickKeyDownSuggestionProps = mkOnClickKeyDown({
            onClick: onSuggestionClicked,
          });

          return (
            <li
              key={`${completion.text} ${i}`}
              data-test-id={`citations-${completionType}-suggestion`}
              className={suggestionClass}
              role="option"
              aria-selected={selectedSuggestionIndex === i}
              onMouseEnter={() => this.selectSuggestion(i)}
              onMouseDown={this.onSuggestionMouseDown}
              {..._onClickKeyDownSuggestionProps}>
              <HighlightedField
                className="suggestion__text truncate-line"
                field={getHighlightedFieldFromJS(completion)}
              />
              {completionType && (
                <span className="suggestion-text-type">&nbsp;{shortTypeLabel}</span>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  render = () => {
    const { formId, containerClass, placeholder, isSearchbarFocused, suggestionType } = this.props;
    const { queryText } = this.state;

    return (
      <form
        id={formId ? formId : 'dropdown-filters__search-within-form'}
        className="dropdown-filters__search-within-form"
        role="search"
        onSubmit={this.onSubmitQueryString}>
        <FlexContainer className={containerClass ? containerClass : 'search-within'}>
          <CLTextInput
            type="search"
            name="cite_q"
            id="search-within-input"
            className={classNames('dropdown-filters__search-within-input', {
              expanded: isSearchbarFocused,
              'author-search': suggestionType === SuggestionType.AUTHOR_PAPER,
            })}
            autoComplete="off"
            onChange={this.onChangeQueryText}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            placeholder={placeholder}
            value={queryText}
            data-test-id="search-within-input"
          />
          <button
            aria-label={getString(_ => _.appHeader.searchSubmitAriaLabel)}
            className="form-submit form-submit__icon-text"
            data-test-id="submit-search-within-input">
            <div className="flex-row-vcenter">
              <Icon width="14" height="14" icon="search-small" />
            </div>
          </button>
        </FlexContainer>
        {this.shouldDisplaySuggestions() ? this.renderSuggestions() : null}
      </form>
    );
  };
}
