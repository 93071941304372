/* eslint-disable react/no-multi-comp */

import { getString } from '@/content/i18n';
import ClickEvent from '@/analytics/models/ClickEvent';
import ContentWithHTML from '@/components/util/ContentWithHTML';
import EventTarget from '@/analytics/constants/EventTarget';
import Link from '@/router/Link';
import queryRoutes from '@/utils/routing/query-routes';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

export default class EmptySearchResults extends React.PureComponent {
  componentDidMount() {
    trackAnalyticsEvent(
      ShowEvent.create(EventTarget.Serp.NO_PAPERS_FOUND, {
        query: this.props.query,
        suggestedQuery: this.props.suggestedQuery,
      })
    );
  }

  render() {
    const { hasFacets, isMobile, query, suggestedQuery } = this.props;

    let backLink = null;
    // Only show the message prompting the user to go backwards if the user has facets, is in the
    // browser, has the history API and has visited at least one S2 page prior to landing on the
    // SERP.
    if (hasFacets && typeof window !== 'undefined' && window.history && window.history.length > 0) {
      backLink = (
        <button onClick={() => window.history.back()} className="link-button">
          {getString(_ => _.search.emptyResults.removeFacetsLabel)}
        </button>
      );
    }
    const messageElem = (() => {
      // This is a special case in which the Search Within Publications path for AHP and PDP where
      // a filter can be applied without a query string.
      if (hasFacets && query === '') {
        return <ContentWithHTML content={_ => _.search.emptyResults.labelWithFacetsNoQuery} />;
      }
      if (hasFacets && query) {
        return (
          <ContentWithHTML content={_ => _.search.emptyResults.labelWithFacets} args={[query]} />
        );
      }
      if (query) {
        return (
          <ContentWithHTML content={_ => _.search.emptyResults.labelWithQuery} args={[query]} />
        );
      }
      return <ContentWithHTML content={_ => _.search.emptyResults.labelWithoutQuery} />;
    })();
    return (
      <div className={isMobile ? 'padded' : ''}>
        {!isMobile ? (
          <h1 className="bold" data-test-id="no-papers-found">
            {getString(_ => _.search.emptyResults.headline)}
          </h1>
        ) : null}
        <p>{messageElem}</p>
        {backLink}
        {suggestedQuery && <SuggestedQuery suggestedQuery={suggestedQuery} />}
      </div>
    );
  }
}

const SuggestedQuery = ({ suggestedQuery }) => {
  function handleSuggestedQueryClick() {
    trackAnalyticsEvent(
      ClickEvent.create(EventTarget.Serp.SUGGESTED_QUERY, {
        suggestedQuery: suggestedQuery,
      })
    );
  }

  return (
    <p className="query-suggestions">
      {getString(_ => _.search.emptyResults.suggestionText) + ' '}
      <strong>
        <Link {...queryRoutes.routeForQuery(suggestedQuery)} onClick={handleSuggestedQueryClick}>
          {suggestedQuery}
        </Link>
        ?
      </strong>
    </p>
  );
};

SuggestedQuery.propTypes = {
  suggestedQuery: PropTypes.string.isRequired,
};
