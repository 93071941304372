/* eslint-disable react/no-multi-comp */

import { format, pluralize } from '@/util';
import { makePath } from '@/router/Routes';
import Api from '@/api/Api';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import Icon from '@/components/shared/icon/Icon';
import InfoBox from '@/components/shared/info-box/InfoBox';
import Link from '@/router/Link';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import type { MatchedAuthorRecord } from '@/models/MatchedAuthor';
import type { Nullable, ReactNodeish } from '@/utils/types';

type LinkProps = {
  author: MatchedAuthorRecord;
  index: number;
  displayAsInfoBox?: Nullable<boolean>;
};

type Props = {
  authors: Immutable.List<MatchedAuthorRecord>;
  queryString: string;
  displayAsInfoBox?: Nullable<boolean>;
};

type State = {
  limit: number;
  hasReportedProblem: boolean;
  hideReportProblem: boolean;
};

const DEFAULT_DISPLAY_AUTHOR_LIMIT = 3;

const MatchedAuthorCitationLabel = ({
  influentialCitationCount,
}: {
  influentialCitationCount: number;
}): ReactNodeish => (
  <span className="matched-author-list__citations">
    <span className="matched-author-list__comma-prefix">{', '}</span>
    <em className="text--orange">{format(influentialCitationCount)}</em> highly influential{' '}
    {pluralize(influentialCitationCount, 'citation', 'citations', true)}
  </span>
);

MatchedAuthorCitationLabel.propTypes = {
  influentialCitationCount: PropTypes.number.isRequired,
};

class MatchedAuthorLink extends PureComponent<LinkProps> {
  static defaultProps = {
    displayAsInfoBox: false,
  };

  trackAuthorLinkClick = (): void => {
    trackAnalyticsEvent(
      ClickEvent.create(EventTarget.Serp.MATCHED_AUTHOR, {
        authorId: this.props.author.id,
        index: this.props.index,
      })
    );
  };

  render(): ReactNodeish {
    const { id: authorId, slug, name, influentialCitationCount } = this.props.author;
    return (
      <div>
        <Link
          to="AUTHOR_PROFILE"
          params={{ authorId, slug }}
          onClick={this.trackAuthorLinkClick}
          className="matched-author-list__author-name">
          <em>{name}</em>
        </Link>
        {influentialCitationCount ? (
          <MatchedAuthorCitationLabel influentialCitationCount={influentialCitationCount} />
        ) : null}
      </div>
    );
  }
}

export default class MatchedAuthorList extends PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
  };

  declare context: {
    api: Api;
  };

  hideReportProblem: boolean = false;
  hideReportProblemsTimer: NodeJS.Timer;

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      limit: DEFAULT_DISPLAY_AUTHOR_LIMIT,
      // (tmurray) Hide the report problem link until we implement a better solution
      hasReportedProblem: true,
      hideReportProblem: true,
    };
  }

  componentDidMount(): void {
    trackAnalyticsEvent(
      ShowEvent.create(EventTarget.Serp.MATCHED_AUTHOR_LIST, {
        count: this.props.authors.size,
        // event data is map of String -> String, so express the author ids as a serialized array
        // that can be parsed on the flip side
        authorIds: JSON.stringify(this.props.authors.map(a => a.id)),
      })
    );
  }

  toggleDisplayLimit = (): void => {
    this.setState(prevState => {
      const limit =
        prevState.limit === DEFAULT_DISPLAY_AUTHOR_LIMIT
          ? this.props.authors.size
          : DEFAULT_DISPLAY_AUTHOR_LIMIT;

      trackAnalyticsEvent(
        ClickEvent.create(EventTarget.Serp.MATCHED_AUTHOR_LIST_TOGGLE, {
          more: limit === this.props.authors.size,
        })
      );

      return { limit };
    });
  };

  submitMatchError = (): void => {
    const authorList = this.props.authors.map(author => {
      const path = makePath({
        routeName: 'AUTHOR_PROFILE_BY_ID',
        params: { authorId: author.id },
      });
      const href = `${document.location.protocol}//${document.location.host}${path}`;
      return {
        id: author.id,
        name: author.name,
        href,
      };
    });
    const matchError = {
      query: this.props.queryString,
      matchedAuthors: authorList,
    };

    this.context.api.submitFeedback(
      `Potential Author Match Issue | ${JSON.stringify(matchError)}`,
      // @ts-expect-error -- Feedback is disabled, so this code will never run
      's2-author-match-error@allenai.org'
    );

    this.setState({ hasReportedProblem: true });
  };

  componentDidUpdate(): void {
    // Hide the "Thanks! We'll look into that." message after it's been displayed for 5 seconds
    if (this.state.hasReportedProblem && !this.hideReportProblem) {
      this.hideReportProblemsTimer = setTimeout(
        this.setState.bind(this, { hideReportProblem: true }),
        5000
      );
    }
  }

  componentWillUnmount(): void {
    if (this.hideReportProblemsTimer) {
      clearTimeout(this.hideReportProblemsTimer);
    }
  }

  renderAuthorList(): ReactNodeish {
    const authorsToDisplay = this.props.authors.take(this.state.limit);
    const showToggleMoreLessButton = this.props.authors.size > DEFAULT_DISPLAY_AUTHOR_LIMIT;

    const reportProblemsCssClass = classNames('matched-author-list__report-problems', {
      'matched-author-list__report-problems--with-divider': showToggleMoreLessButton,
      'matched-author-list__report-problems--problem-reported': this.state.hasReportedProblem,
    });

    return (
      <div className="matched-author-list">
        {!this.props.displayAsInfoBox ? (
          <div className="flex-row-vcenter">
            <Icon
              icon="author"
              className="matched-author-list__header-icon"
              width="18"
              height="18"
            />
            <span>
              Author profiles for <em>{this.props.queryString}</em>:
            </span>
          </div>
        ) : null}
        <ul>
          {authorsToDisplay.map((author, index) => (
            <li key={`matched-author-${author.id}-${index}`}>
              <MatchedAuthorLink author={author} index={index} />
            </li>
          ))}
        </ul>
        {showToggleMoreLessButton ? (
          <button
            onClick={this.toggleDisplayLimit}
            className="link-button"
            data-test-id="matched-author-list-toggle">
            {this.props.authors.size > this.state.limit ? 'See More' : 'See Less'}
          </button>
        ) : null}
        {!this.state.hideReportProblem && this.props.authors.size > 1 ? (
          <span className={reportProblemsCssClass}>
            {!this.state.hasReportedProblem ? (
              <button onClick={this.submitMatchError} className="link-button">
                Report duplicate authors
              </button>
            ) : (
              <span>
                <Icon icon="check" width="14" height="14" /> Thanks! We’ll look into it.
              </span>
            )}
          </span>
        ) : null}
      </div>
    );
  }

  render(): ReactNodeish {
    return this.props.displayAsInfoBox ? (
      <InfoBox title="Authors" icon="author">
        {this.renderAuthorList()}
      </InfoBox>
    ) : (
      this.renderAuthorList()
    );
  }
}
