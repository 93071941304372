import { AHP_CONFIG } from '@/components/shared/filter/DropdownFilterConfig';
import {
  buildBaseClickContext,
  trackSearchResultAuthorClick,
} from '@/components/shared/search/SearchResultActionTracking';
import { citationStatsHasKeyCitations } from '@/models/CitationStats';
import { getString } from '@/content/i18n';
import { mapAppContextToProps, useAppContext, useStateFromStore } from '@/AppContext';
import { queryResponseHasFacets, QueryResponseRecord } from '@/models/QueryResponse';
import { SuggestionType } from '@/models/SuggestionType';
import { UserSettingKey, UserSettingValue } from '@/models/user/UserSetting';
import { useUserSettingUtils } from '@/utils/user-setting-utils';
import AuthorQueryStore from '@/stores/AuthorQueryStore';
import BrowserUtil from '@/browser';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import CLPager from '@/components/library/pager/CLPager';
import CLPaperAnalytics, {
  defaultOnClickAuthor,
} from '@/components/library/paper/CLPaperAnalytics';
import CLPaperAuthors from '@/components/library/paper/CLPaperAuthors';
import CLPaperControls from '@/components/library/paper/CLPaperControls';
import CLPaperMeta from '@/components/library/paper/CLPaperMeta';
import CLPaperRow from '@/components/library/paper/CLPaperRow';
import CLPaperStats from '@/components/library/paper/stats/CLPaperStats';
import CLPaperTitle from '@/components/library/paper/CLPaperTitle';
import DropdownFilterControls from '@/components/shared/filter/DropdownFilterControls';
import EmptySearchResults from '@/components/shared/search/EmptySearchResults';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import Feature from '@/weblab/Feature';
import MatchedAuthorList from '@/components/shared/search/MatchedAuthorList';
import QueryRoutes from '@/utils/routing/query-routes';
import QueryStoreState from '@/constants/query-state';
import S2History from '@/utils/S2History';
import SearchLayoutOptions from '@/components/shared/search/SearchLayoutOptions';
import SearchWithinTextSearch from '@/components/shared/search/SearchWithinTextSearch';
import ShowEvent from '@/analytics/models/ShowEvent';
import SortSelector from '@/components/shared/common/sort/SortSelector';
import SortType from '@/constants/sort-type';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import TldrToggle from '@/components/shared/search/TldrToggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import UserPreferencesStore, { UserPreference } from '@/stores/UserPreferencesStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import type { AuthorDetailsRecord } from '@/models/author/AuthorDetails';
import type { Nullable, ReactNodeish, TODO } from '@/utils/types';
import type { PaperRecord } from '@/models/Paper';
import type { QueryRecord } from '@/models/Query';

type TODO__QueryStoreState = TODO<'QueryStory should be exporting this when it is converted to TS'>;
type TODO__PublicationSort = TODO<'Not sure what a "publicationSort" is'>;

type PropsFromAppContext = {
  isLoading: boolean;
  isCompact: boolean;
  isMobile: boolean;
  queryStoreState: TODO__QueryStoreState;
  query: QueryRecord;
  queryString: string;
  response: QueryResponseRecord;
  isFiltering: boolean;
  publicationSort: TODO__PublicationSort;
  authorDetail: Nullable<AuthorDetailsRecord>;
  setPersistentUserSetting: (key: UserSettingKey, value: any) => void;
  isAHPSortFeatureEnabled: boolean;
  hasAuthenticatedUser: boolean;
  sort: UserSettingValue;
};

type Props = {
  layoutOptions: SearchLayoutOptions;
} & PropsFromAppContext;

type State = {
  isSearchbarFocused: boolean;
  queryString: Nullable<string>;
  sort: Nullable<TODO__PublicationSort>;
};

export class AuthorPapersSearch extends React.PureComponent<Props, State> {
  static contextTypes = {
    analyticsLocation: PropTypes.object,
    authorQueryStore: PropTypes.instanceOf(AuthorQueryStore).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    router: PropTypes.object.isRequired,
    userPrefsStore: PropTypes.instanceOf(UserPreferencesStore).isRequired,
  };

  declare context: {
    analyticsLocation: Nullable<object>;
    authorQueryStore: AuthorQueryStore;
    envInfo: EnvInfo;
    history: S2History;
    router: object;
    userPrefsStore: UserPreferencesStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isSearchbarFocused: false,
      queryString: null,
      sort: this.props.sort,
    };
  }

  componentDidMount(): void {
    const { isCompact, isMobile } = this.props;
    const hideAbstract = !isMobile && isCompact;

    if (hideAbstract) {
      trackAnalyticsEvent(ShowEvent.create(EventTarget.AuthorHomePage.DENSE_PAPER_VIEW));
    } else {
      trackAnalyticsEvent(ShowEvent.create(EventTarget.AuthorHomePage.NORMAL_PAPER_VIEW));
    }
  }

  isUserLoggedInAndFeatureEnabled() {
    const { isAHPSortFeatureEnabled, hasAuthenticatedUser } = this.props;
    return isAHPSortFeatureEnabled && hasAuthenticatedUser;
  }

  onChangeSort = (sort: TODO__PublicationSort): void => {
    const { setPersistentUserSetting } = this.props;
    const sortSetting = { ahpPapersSearchSort: sort };
    this.context.authorQueryStore.routeToSort(sort, this.context.router);
    if (this.isUserLoggedInAndFeatureEnabled()) {
      setPersistentUserSetting(UserSettingKey.ahpPapersSearchSort, sortSetting);
      this.setState({ sort });
    }
  };

  onChangeYearFilter = (min: string, max: string): void => {
    const { authorQueryStore, router } = this.context;
    authorQueryStore.routeToYearRange(min, max, router);
  };

  onChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ queryString: event.currentTarget.value });
  };

  onSearchbarFocus = (): void => {
    this.setState({
      isSearchbarFocused: true,
    });
  };

  onSearchbarCondense = (): void => {
    const searchElement = document.getElementById('search-within-input');
    if (searchElement) {
      searchElement.blur();
    }
    this.setState({
      isSearchbarFocused: false,
    });
  };

  onSearchWithin = (event: React.MouseEvent): void => {
    event.preventDefault();

    const queryString = (this.state.queryString || this.props.queryString).trim();
    const newQuery = this.props.query.merge({
      queryString: queryString,
      page: 1,
    });
    QueryRoutes.changeRouteForQuery(newQuery, this.context.history, this.context.router);

    trackAnalyticsEvent(
      SubmitEvent.create(EventTarget.AuthorHomePage.Publications.SEARCH, { queryString })
    );
  };

  clearQueryString = (): void => {
    this.setState({
      queryString: '',
    });
  };

  loadPage = (page: number): void => {
    if (page !== this.props.query.page) {
      const node = ReactDOM.findDOMNode(this);
      let alternatePosition: Nullable<number> = null;

      if (node instanceof HTMLElement) {
        alternatePosition = BrowserUtil.offsetFromBody(node);
      }

      BrowserUtil.scrollTop(this.props.layoutOptions.scrollToTopOfPage ? 0 : alternatePosition);
      this.context.authorQueryStore.routeToPage(page, this.context.router);
    }
  };

  trackAuthorClick = ({ paper, eventData, compData }): void => {
    const { analyticsLocation } = this.context;
    const { authorId } = compData;

    trackSearchResultAuthorClick({
      ...buildBaseClickContext(this.context.authorQueryStore, {
        paperSha: paper.id,
        paperIndex: eventData.index,
      }),
      authorId: parseInt(authorId),
    });

    defaultOnClickAuthor({ paper, analyticsLocation, eventData, compData });
  };

  renderStats(paper: PaperRecord): React.ReactNode {
    if (!paper.citationStats) {
      return null;
    }

    const hasCitationStats =
      paper.citationStats.numCitations > 0 || citationStatsHasKeyCitations(paper.citationStats);

    if (!hasCitationStats) {
      return null;
    }

    const { publicationSort } = this.props;
    const hic = publicationSort === SortType.INFLUENTIAL_CITATIONS.id;
    return <CLPaperStats paper={paper} hic={hic} />;
  }

  renderResults(): React.ReactNode {
    const {
      authorDetail,
      isCompact,
      isMobile,
      query,
      response: { results },
    } = this.props;
    const hideAbstract = !isMobile && isCompact;

    const rowClasses = classnames('author-page-details__paper-row', {
      'paper-row-dense': hideAbstract,
      'paper-row-normal': !hideAbstract,
    });

    return results.map((result, i) => (
      <CLPaperAnalytics
        key={result.id}
        paper={result}
        eventData={{
          authorId: authorDetail?.author?.id,
          index: (query.page - 1) * query.pageSize + i,
        }}
        onClickAuthor={this.trackAuthorClick}
        onHoverStats={true}>
        <CLPaperRow
          paper={result}
          className={rowClasses}
          meta={
            <CLPaperMeta
              paper={result}
              authors={<CLPaperAuthors paper={result} onWhiteBackground={false} />}
            />
          }
          controls={<CLPaperControls paper={result} stats={this.renderStats(result)} />}
          abstract={hideAbstract ? false : <TldrToggle paper={result} query={query.queryString} />}
          title={<CLPaperTitle paper={result} titleTagName="h2" />}
        />
      </CLPaperAnalytics>
    ));
  }

  renderNoResults(): React.ReactNode {
    const { querySuggestions } = this.props.response;
    const emptyQuerySuggestion = querySuggestions && !querySuggestions.isEmpty() ? false : true;
    const suggestedQueryText = !emptyQuerySuggestion ? querySuggestions.first()?.text : undefined;

    return (
      <EmptySearchResults
        query={this.props.query.queryString}
        hasFacets={queryResponseHasFacets(this.props.response)}
        suggestedQuery={suggestedQueryText}
      />
    );
  }

  renderSearchControl(): ReactNodeish {
    const { isSearchbarFocused } = this.state;
    const { authorQueryStore, envInfo } = this.context;

    if (envInfo.isMobile) {
      // Don't render the search bar on mobile
      return null;
    }

    return (
      <React.Fragment>
        <SearchWithinTextSearch
          formId="author-paper-search-text-form"
          containerClass="search-within"
          placeholder={getString(_ => _.author.searchPlaceholder)}
          suggestionType={SuggestionType.AUTHOR_PAPER}
          injectQueryStore={authorQueryStore}
          isSearchbarFocused={isSearchbarFocused}
          onSearchbarCondense={this.onSearchbarCondense}
          onSearchbarFocus={this.onSearchbarFocus}
        />
        {isSearchbarFocused && (
          <div className="dropdown-filters__modal-button">
            <CLButton
              type={BUTTON_TYPE.SECONDARY}
              label={getString(_ => _.filterBar.dropdownLabels.showFilters)}
              onClick={this.onSearchbarCondense}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    const { isSearchbarFocused, sort } = this.state;
    const { layoutOptions, isFiltering, response, query } = this.props;
    const { authorQueryStore } = this.context;
    const { results, matchedAuthors } = response;

    const selectedSort = this.isUserLoggedInAndFeatureEnabled() ? sort : query.sort;

    return (
      <div className="author-papers-search">
        {!matchedAuthors.isEmpty() ? (
          <MatchedAuthorList queryString={query.queryString} authors={matchedAuthors} />
        ) : null}
        <DropdownFilterControls
          className="author-page-content__filters dropdown-filters-breakpoints__ahp"
          config={AHP_CONFIG}
          onChangeYearFilter={this.onChangeYearFilter}
          query={query}
          response={response}
          sortControl={
            <SortSelector
              className="dropdown-filters__sort-control"
              onChangeSort={this.onChangeSort}
              options={layoutOptions.sorts}
              sort={selectedSort}
            />
          }
          injectQueryStore={authorQueryStore}
          isSearchbarFocused={isSearchbarFocused}
          doFullQueryReset
          searchControl={this.renderSearchControl()}
        />
        <div
          className={classnames('result-page', { 'is-filtering': isFiltering })}
          data-test-id="result-page"
          data-selenium-is-filtering={isFiltering || undefined}>
          {!results.isEmpty() ? this.renderResults() : this.renderNoResults()}
        </div>
        <div className="flex-row">
          {response.totalPages > 1 && (
            <CLPager
              onPaginate={this.loadPage}
              pageNumber={query.page}
              totalPages={response.totalPages}
              data-test-id="result-page-pagination"
            />
          )}
        </div>
      </div>
    );
  }
}

export default mapAppContextToProps<Props, PropsFromAppContext>(AuthorPapersSearch, appContext => {
  const authorStoreProps = useStateFromStore(appContext.authorStore, _ => ({
    isLoading: _.isLoading(),
    authorDetail: _.getAuthorDetails(),
  }));

  const authorQueryStoreProps = useStateFromStore(appContext.authorQueryStore, _ => ({
    isLoading: _.isLoading(),
    queryStoreState: _.getQueryState(),
    query: _.getQuery(),
    queryString: _.getQueryString(),
    response: _.getQueryResponse(),
    isFiltering: _.getQueryState() === QueryStoreState.FILTERING,
    publicationSort: _.getSort(),
  }));

  const envStoreProps = {
    isMobile: appContext.envInfo.isMobile,
  };

  const userPrefsStoreProps = useStateFromStore(appContext.userPrefsStore, _ => ({
    isCompact: !!_.getLocalPreference(UserPreference.DENSE_PAPER_VIEW),
  }));

  const { weblabStore, authStore, userSettingStore } = useAppContext();
  const hasAuthenticatedUser = authStore.hasAuthenticatedUser();
  const ahpPapersSearchSortRecord = userSettingStore.getUserSetting(
    UserSettingKey.ahpPapersSearchSort
  );
  const sort = ahpPapersSearchSortRecord?.settingsValue?.ahpPapersSearchSort;
  const isAHPSortFeatureEnabled = weblabStore.isFeatureEnabled(Feature.AHPSortPref);

  const { setPersistentUserSetting } = useUserSettingUtils();
  return {
    ...authorStoreProps,
    ...authorQueryStoreProps,
    ...envStoreProps,
    ...userPrefsStoreProps,
    isLoading: authorQueryStoreProps.isLoading && authorStoreProps.isLoading,
    setPersistentUserSetting,
    isAHPSortFeatureEnabled,
    hasAuthenticatedUser,
    sort,
  };
});
