import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import Arrow from '@/components/shared/icon/Arrow';
import Icon from '@/components/shared/icon/Icon';
import Link, { S2LinkProps } from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

import type { IconId } from '@/components/shared/icon/IconSprite';
import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  className?: Nullable<string>;
  icon?: Nullable<IconId>;
  isCollapsed?: boolean;
  isCollapsible?: boolean;
  onToggle?: () => void;
  startOpen?: boolean;
  title: string;
  titleLinkProps?: Nullable<S2LinkProps>;
}>;

type State = {
  isCollapsed: boolean;
};

export default class InfoBox extends React.PureComponent<Props, State> {
  static defaultProps = {
    isCollapsible: false,
    startOpen: false,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isCollapsed: !!(this.props.isCollapsible && !this.props.startOpen),
    };
  }

  onToggleClick = (): void => {
    this.setState(({ isCollapsed }) => ({ isCollapsed: !isCollapsed }));

    if (this.props.onToggle) {
      this.props.onToggle();
    }
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.onToggleClick,
  });

  renderTitle(): ReactNodeish {
    const { icon, title } = this.props;
    return (
      <span className="info-box__title flex-row-vcenter">
        {icon ? <Icon icon={icon} className="flex-static" width="25" height="25" /> : null}
        <h3>{title}</h3>
      </span>
    );
  }

  renderHeader(): ReactNodeish {
    const { isCollapsible, title, titleLinkProps } = this.props;
    const { isCollapsed } = this.state;
    const arrowDirection = isCollapsed ? Arrow.Direction.DOWN : Arrow.Direction.UP;

    if (title) {
      return (
        <div
          className="info-box__header flex-row-vcenter flex-space-between"
          {...this._onClickKeyDownProps}
          role="button"
          tabIndex={0}>
          {titleLinkProps ? (
            <Link {...titleLinkProps}>{this.renderTitle()}</Link>
          ) : (
            this.renderTitle()
          )}
          {isCollapsible ? (
            <span className="info-box__toggle">
              <Arrow className="flex-static" direction={arrowDirection} width="14" height="14" />
            </span>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }

  render(): ReactNodeish {
    const { className, children } = this.props;
    const { isCollapsed } = this.state;
    const parentClassName = classnames('info-box', className);

    return (
      <div className={parentClassName}>
        {this.renderHeader()}
        {!isCollapsed ? <div className="info-box__content">{children}</div> : null}
      </div>
    );
  }
}
