import AuthorPapersSearch from './AuthorPapersSearch';

import SearchLayoutOptions from '@/components/shared/search/SearchLayoutOptions';
import SortType from '@/constants/sort-type';

import React from 'react';

export default function AuthorPapersPage(): React.ReactElement {
  const layoutOptions = new SearchLayoutOptions({
    showSortExplanation: true,
    filtersExpandedByDefault: true,
    showResultCount: false,
    sortDisplayType: 'tabs',
    scrollToTopOfPage: false,
    searchResultLayout: {
      showSortStat: true,
    },
    sorts: SortType.authorPaperSearch(),
  });

  return (
    <div className="author-papers-page">
      <div className="author-page-content-section">
        <div className="results">
          <AuthorPapersSearch layoutOptions={layoutOptions} />
        </div>
      </div>
    </div>
  );
}
